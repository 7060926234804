@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #f15a2d;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #f15a2d !important;
}

.rdrDateDisplayWrapper {
  display: none;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}

.rdw-editor-main {
  background-color: white;
  padding: 0 16px;
  min-height: 360px;
  max-height: 480px;
  overflow-y: auto;
}

.rdw-image-modal,
.rdw-embedded-modal,
.rdw-emoji-modal,
.rdw-link-modal {
  right: 5px !important;
  left: -250px !important;
  width: 280px !important;
}
